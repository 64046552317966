@use './utils' as util;

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  height: 75vh;

  .title {
    color: var(--primary-color);
    @include util.AdaptiveSize(font-size, 20px, 46px, 377px, 1025px);
    font-weight: 600;
    line-height: 1.5;
  }

  .text {
    color: rgb(255, 255, 255, 0.7);

    @include util.AdaptiveSize(font-size, 16px, 20px, 377px, 1025px);
  }

  .button {
    padding-block: 15px;
  }
}



.container_errors {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 130px;
  height: 100%;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 600px) {
    gap: 100px;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    .main_images {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @media (max-width: 600px) {
        width: 335px;
      }
    }

    .main_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .main_title {
        text-align: center;
        font-family: var(--font-inter);
        color:  #939bc2;
        font-style: normal;
        font-weight: 600;
        line-height: 1.2;
        text-transform: uppercase;
        @include util.AdaptiveSize(font-size, 12px, 14px, 377px, 1025px);

        @media (max-width: 600px) {
          width: 270px;
        }
      }

      .main_button {
        max-width: 278px;
        max-height: 48px;
        border-radius: 120px;
        border: 4px solid rgba(211, 248, 90, 0.16);
        font-family: var(--font-inter);
        font-weight: 700;
        text-transform: none;
        font-size: 18px;
        color: #0C1027;
      }
    }
  }
}
